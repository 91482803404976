export default {
  theValentinesEdit: {
    en: `The Valentine's Edit`,
  },
  theLuxeEdit: {
    en: 'The Luxe Edit',
  },
  theMothersDayEdit: {
    en: `Mother's Day Edit`,
  },
  theFathersDayEdit: {
    en: `The Men's Edit`,
  },
}
