import React from 'react'
import links from 'links'
import { track } from 'analytics'
import { usePathname } from 'router'

import useFt from 'hooks/useFt'
import { constants } from 'helpers'

import { useAb } from 'hooks'
import { useUser } from 'modules/user'

import { Href } from 'components/navigation'
import { Logo, Text } from 'components/dataDisplay'
import { buttonMessages } from 'components/inputs'

import LandingSubscriptionHeaderBanner from 'compositions/banners/LandingSubscriptionHeaderBanner/LandingSubscriptionHeaderBanner'
import LandingSubscriptionHeaderBannerAb from 'compositions/banners/LandingSubscriptionHeaderBannerAb/LandingSubscriptionHeaderBannerAb'
import TwelveDaysBanner from 'compositions/banners/TwelveDaysBanner/TwelveDaysBanner'
import Header from 'compositions/Header/Header'
import { MainHeader } from 'compositions/Header/components'
import Footer from 'compositions/Footer/Footer'


const LandingLayout: React.CFC = ({ children }) => {
  const pathname = usePathname()

  const { isLoggedIn } = useUser()

  const isHomePage = (
    pathname === links.home
    || pathname === links.homeMen
  )
  const isTwelveDaysPages = pathname === links.twelveDays

  const isBlackFridayBannerVisible = useFt(constants.features.blackFridayTopHomePageBanner)
  const isCyberMondayBannerVisible = useFt(constants.features.cyberMondayTopHomePageBanner)
  const isSubscribeBannerAbEnabled = useAb(constants.abTests.globalSubscribeBannerUpdate) === 'b'

  return (
    <div className="flex min-h-full flex-col">
      {
        !isHomePage && !isTwelveDaysPages && (
          isSubscribeBannerAbEnabled ? (
            <LandingSubscriptionHeaderBannerAb />
          ) : (
            <LandingSubscriptionHeaderBanner />
          )
        )
      }
      {
        isTwelveDaysPages && (
          <TwelveDaysBanner />
        )
      }
      {
        isHomePage || isTwelveDaysPages ? (
          <Header
            logoLink={null}
            withSubHeader={false}
            withSupNav={false}
            withBanner={isBlackFridayBannerVisible || isCyberMondayBannerVisible}
          />
        ) : (
          <MainHeader>
            <div className="flex-1" /> {/* flex placeholder */}
            <Logo className="flex-1 text-center" color="white" />
            <div className="mb-4 flex-1 text-right">
              {
                !isLoggedIn && (
                  <Href
                    to={`${links.login}?redirect=${encodeURIComponent(links.subscribe)}`}
                    onClick={() => {
                      track('Login button click')
                    }}
                    data-testid="loginButton"
                  >
                    <Text tag="span" message={buttonMessages.login} style="t2" />
                  </Href>
                )
              }
            </div>
          </MainHeader>
        )
      }
      <main id="main" className="flex-auto">
        {children}
      </main>
      <Footer />
    </div>
  )
}


export default LandingLayout
